<template>
  <div
    ref="recommendations"
    :class="{ 'ShowRecommendations': showRecommendations }"
    class="Questions">
    <!-- Questions -->
    <div class="QuestionsList">
      <QuestionItem
        v-for="(item, index) in questionnaireQuestions"
        :key="item.id"
        :item="item"
        :index="index"
        :answerItem="answers[item.id]"
        :showRecommendations="showRecommendations"
        :selectedQuestionID="selectedQuestionID"
        @answer-update="onAnswerUpdate"
        @comment-update="onCommentUpdate"
        @info-click="onInfoClick" />

      <div
        v-if="showRecommendations"
        class="RecommendationWrap">
        <span>{{ mixWB('WE_RECOMMEND') }}</span>
      </div>
    </div>

    <!-- Recommendations -->
    <Recommendations
      v-if="showRecommendations"
      :selectedQuestionID="selectedQuestionID"
      :comments="comments" />
  </div>

  <div class="ButtonsWrap">
    <span class="SaveAgainText">{{ mixWB('SAVE_AT_ANY_TIME_TEXT') }}</span>

    <Button
      :text="saveButtonText"
      :fullWidth="false"
      :isDisabled="isSaveButtonDisabled"
      :isLoading="isSaving"
      @button-click="onSaveClick" />

    <template v-if="showRecommendations">
      <Button
        :text="nextButtonText"
        :fullWidth="false"
        @button-click="onNextClick" />
    </template>
  </div>
</template>

<script>
import QuestionItem from '@/components/Questionnaire/QuestionItem'
import Button from '@/components/Buttons/Button'
import { scrollTo } from '@/globals/javascript/util'
import { mapGetters } from 'vuex'
import { clone, sortBy } from 'lodash-es'
import Recommendations from '@/views/Recommendations'

export default {
  name: 'Questions',
  emits: [
    'show-recommendations',
    'show-info-on-save',
  ],
  props: {
    showRecommendations: {
      type: Boolean,
      required: true,
    },
    comments: {
      type: [Array, Boolean],
      required: true,
    },
  },
  data() {
    return {
      answers: {},
      isSaving: false,
      detectedChanges: false,
      selectedQuestionID: null,
    }
  },
  computed: {
    ...mapGetters([
      'questionSchemas',
      'currentUserAnswers',
      'currentQuestionSchema',
      'currentQuestionSchemaSavedUserAnswers',
      'currentUserNextStep',
    ]),
    questionnaireQuestions() {
      const sortedQuestions = sortBy(this.currentQuestionSchema.questions, ['questionGroupID', 'position'])

      return sortedQuestions.reduce((prev, question) => {
        if (!question.questionGroupID) {
          prev.push(question)
          return prev
        }

        const questionGroup = this.currentQuestionSchema.questionGroups.find(
          (x) => x.id === question.questionGroupID,
        )

        if (!questionGroup) {
          prev.push(question)
          return prev
        }

        // Check if group ID is same as last group ID
        question.showGroupTitleInList = true
        if (prev.length && prev[prev.length - 1].questionGroupID === question.questionGroupID) {
          question.showGroupTitleInList = false
        }

        question.groupTitle = this.mixWB(questionGroup.wordbook)
        prev.push(question)
        return prev
      }, [])
    },
    allQuestionsAnswered() {
      return !Object.keys(this.answers).find((key) => this.answers[key].answer === null)
    },
    saveButtonText() {
      if (this.allQuestionsAnswered) {
        return this.mixWB('SAVE_CHANGES')
      }

      return this.mixWB('SAVE_ANSWERS')
    },
    nextButtonText() {
      return this.currentUserNextStep.allDone
        ? this.mixWB('DONE')
        : this.mixWB('CONTINUE')
    },
    isSaveButtonDisabled() {
      if (!this.allQuestionsAnswered) {
        return true
      }
      if (this.allQuestionsAnswered && !this.detectedChanges) {
        return true
      }
      return false
    },
  },
  methods: {
    onAnswerUpdate({ questionID, answer }) {
      this.answers[questionID].answer = answer
      this.detectedChanges = true
    },
    onCommentUpdate({ questionID, comment }) {
      this.answers[questionID].comment.text = comment
      this.detectedChanges = true
    },
    onInfoClick({ questionID }) {
      this.selectedQuestionID = questionID

      scrollTo({
        element: this.$refs.recommendations,
        offset: -60,
        checkBetween: true,
        duration: 500,
      })
    },
    onSaveClick() {
      this.$store.dispatch('setUserAnswers', {
        questionnaireID: this.currentQuestionSchema.id,
        answers: this.answers,
      })

      this.isSaving = true
      setTimeout(() => {
        this.isSaving = false
        this.detectedChanges = false
        this.$emit('show-recommendations')
        scrollTo({
          yPos: 0,
          offset: 200,
          checkBetween: true,
          duration: 500,
        })

        // Show dialog
        this.showInfoDialog()
      }, 250)
    },
    onNextClick() {
      if (this.currentUserNextStep.allDone) {
        this.$router.push({
          name: 'Done',
        })
        return
      }

      this.$router.push({
        name: 'Questionnaire',
        params: { questionnaireName: this.currentUserNextStep.schema.name },
      })
    },
    showInfoDialog() {
      if (this.currentUserNextStep.allDone) {
        return
      }

      const hasBeenShownOnce = window.sessionStorage.getItem('questionSaveInfoBoxShown')
      if (hasBeenShownOnce) {
        return
      }

      window.sessionStorage.setItem('questionSaveInfoBoxShown', '1')

      this.$emit('show-info-on-save')
    },
    setupAnswers() {
      let allAnswered = true
      this.answers = this.currentQuestionSchema.questions.reduce((prev, question) => {
        prev[question.id] = {
          answer: null,
          comment: {
            id: null,
            text: '',
          },
        }

        if (
          this.currentQuestionSchemaSavedUserAnswers
          && this.currentQuestionSchemaSavedUserAnswers.answers[question.id]
        ) {
          prev[question.id].answer = this.currentQuestionSchemaSavedUserAnswers.answers[
            question.id
          ].answer
          prev[question.id].comment = clone(
            this.currentQuestionSchemaSavedUserAnswers.answers[question.id].comment,
          )
        }

        if (allAnswered && prev[question.id].answer === null) {
          allAnswered = false
        }

        return prev
      }, {})

      // Check to show recommendations
      if (allAnswered) {
        this.$emit('show-recommendations')
      }

      // Select answer to show info for
      this.selectedQuestionID = this.questionnaireQuestions[0].id
    },
  },
  components: {
    QuestionItem,
    Button,
    Recommendations,
  },
  created() {
    this.setupAnswers()
  },
}
</script>

<style lang="stylus" scoped>
  .Questions
    display flex
    justify-content center
    margin 0 20px

  .QuestionsList
    position relative
    width 560px
    flex-shrink 0
    .ShowRecommendations &
      padding-right 160px
      width 700px
    +below($huge)
      .ShowRecommendations &
        padding-right 140px
        width 680px

  .RecommendationWrap
    position absolute
    top -45px
    right 0
    height calc(100% + 48px)
    width 160px
    z-index 1
    span
      display block
      text-transform uppercase
      text-align center
      padding 11px 10px 9px
    +below($huge)
      width 140px
      span
        white-space nowrap

  .ButtonsWrap
    display flex
    justify-content space-around
    flex-wrap wrap
    margin-top 20px
    .SaveAgainText
      display block
      width 100%
      text-align center
      margin 0 auto 20px
    > div
      min-width 260px
</style>
