<template>
  <div class="QuestionnaireStartInfoDialog">
    <span class="PreText">{{ mixWB('A_SHORT_INTRO_TO') }}</span>
    <span class="Title">{{ mixWB(currentQuestionSchema.wordbook) }}</span>
    <p
      v-for="wordbook in currentQuestionSchema.introTextWordbooks"
      :key="wordbook"
      v-html="mixWB(wordbook)"/>

    <div class="ButtonWrap">
      <Button
        :text="mixWB('I_UNDERSTAND')"
        @button-click="onButtonClick" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '../Buttons/Button'

export default {
  name: 'QuestionnaireStartInfoDialog',
  emits: [
    'close-dialog',
  ],
  computed: {
    ...mapGetters([
      'currentQuestionSchema',
    ]),
  },
  methods: {
    onButtonClick() {
      this.$emit('close-dialog')
    },
  },
  components: {
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .QuestionnaireStartInfoDialog
    p,
    span
      text-align center
    .PreText
      color $color_grey
    .Title
      font-size 1.5rem
      font-weight bold
      margin-bottom 20px

  .ButtonWrap
    margin-top 20px
</style>
