<template>
  <div class="Recommendations">
    <div class="SegmentSelection">
      <span
        :class="{ Selected: segment === 'all' }"
        @click="segment = 'all'">{{ mixWB('ALL_USERS') }}</span>
      <span
        :class="{ Selected: segment === 'group' }"
        @click="segment = 'group'">{{ getGroupName() }}</span>
    </div>
    <div
      v-if="selectedQuestion.groupTitle"
      class="QuestionGroupHeader">
      <span v-html="selectedQuestion.groupTitle"/>
    </div>

    <span class="Title">{{ mixWB(selectedQuestion.wordbook) }}</span>

    <div
      v-if="currentQuestionStats"
      class="ColumnsWrap">
      <RecommendationStatsColumn
        type="yes"
        :title="mixWB('YES_ALWAYS')"
        :currentQuestionStats="currentQuestionStats"
        :selectedQuestion="selectedQuestion"
        :currentQuestionComments="currentQuestionComments"
        :segment="segment" />
      <RecommendationStatsColumn
        type="no"
        :title="mixWB('NO')"
        :currentQuestionStats="currentQuestionStats"
        :selectedQuestion="selectedQuestion"
        :currentQuestionComments="currentQuestionComments"
        :segment="segment" />
      <RecommendationStatsColumn
        type="both"
        :title="mixWB('YES_AND_NO')"
        :currentQuestionStats="currentQuestionStats"
        :selectedQuestion="selectedQuestion"
        :currentQuestionComments="currentQuestionComments"
        :segment="segment" />
    </div>
  </div>
</template>

<script>
import RecommendationStatsColumn from '@/components/Questionnaire/RecommendationStatsColumn'
import { mapGetters } from 'vuex'

export default {
  name: 'Recommendations',
  props: {
    selectedQuestionID: {
      type: String,
      required: true,
    },
    comments: {
      type: [Array, Boolean],
      required: true,
    },
  },
  data() {
    return {
      segment: 'all',
    }
  },
  computed: {
    ...mapGetters([
      'questionSchemas',
      'currentQuestionSchema',
      'allStatsAnswers',
      'currentGroupData',
      'currentGroupID',
    ]),
    selectedQuestion() {
      return this.currentQuestionSchema.questions.find((x) => x.id === this.selectedQuestionID)
    },
    currentQuestionSchemaAnswerStats() {
      if (this.segment === 'group') {
        return this.currentGroupData.answers[this.currentQuestionSchema.id]
      }

      return this.allStatsAnswers[this.currentQuestionSchema.id]
    },
    currentQuestionStats() {
      if (!this.selectedQuestionID) {
        return false
      }

      return this.currentQuestionSchemaAnswerStats[this.selectedQuestionID]
    },
    currentQuestionComments() {
      if (!this.comments) {
        return false
      }

      if (this.segment === 'group') {
        return this.comments.filter(
          (x) => x.questionID === this.selectedQuestionID
            && x.groupID === this.currentGroupID,
        )
      }

      return this.comments.filter((x) => x.questionID === this.selectedQuestionID)
    },
  },
  methods: {
    getGroupName() {
      return `${
        this.currentGroupData.name
      } (${
        this.currentGroupData.totalMembers === 1
          ? this.mixWB('1_MEMBER')
          : this.mixWB('X_MEMBERS', [this.currentGroupData.totalMembers])
      })`
    },
  },
  components: { RecommendationStatsColumn },
}
</script>

<style lang="stylus" scoped>
  .Recommendations
    position relative
    background-color $color_blue_light_2
    padding 15px
    flex-grow 2
    max-width 900px
    +below($huge)
      padding 10px

  .SegmentSelection
    position absolute
    top -45px
    right 0
    width 100%
    height 45px
    display flex
    justify-content flex-end
    span
      display flex
      align-items center
      padding 0 20px
      border 1px solid $color_blue_light_2
      border-bottom none
      cursor pointer
      &.Selected
        background-color $color_blue_light_2

  .Title
    text-align center
    font-size 1.5rem
    font-weight bold
    margin-bottom 20px

  .QuestionGroupHeader
    text-align center
    padding 0px 20px 20px

  .ColumnsWrap
    display flex
</style>
