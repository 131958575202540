<template>
  <div class="QuestionnaireSaveInfoDialog">
    <span class="Title">{{ mixWB('SAVE_INFO_DIALOG_TITLE') }}</span>
    <p v-html="mixWB('SAVE_INFO_DIALOG_LINE_1')"/>
    <p v-html="mixWB('SAVE_INFO_DIALOG_LINE_2')"/>

    <div class="ButtonWrap">
      <Button
        :text="mixWB('I_UNDERSTAND')"
        @button-click="onButtonClick" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button'

export default {
  name: 'QuestionnaireSaveInfoDialog',
  methods: {
    onButtonClick() {
      this.$emit('close-dialog')
    },
  },
  components: { Button },
}
</script>

<style lang="stylus" scoped>
  .QuestionnaireSaveInfoDialog
    p,
    span
      text-align center
    .Title
      font-size 1.5rem
      font-weight bold
      margin-bottom 20px

  .ButtonWrap
    margin-top 20px
</style>
