<template>
  <div
    :class="{ 'ShowRecommendations': showRecommendations }"
    class="Questionnaire">
    <Header />

    <div class="Intro">
      <span
        class="Title"
        v-html="mixWB(currentQuestionSchema.wordbook)"/>
      <p
        v-for="wordbook in currentQuestionSchema.introTextWordbooks"
        :key="wordbook">
        <span v-html="mixWB(wordbook)"/>
      </p>
    </div>

    <div class="MainWrap">
      <Questions
        :showRecommendations="showRecommendations"
        :comments="currentSchemaComments || false"
        @show-recommendations="onShowRecommendations"
        @show-info-on-save="showSaveInfo = true" />
    </div>

    <Dialog
      :isShowing="showStartInfo"
      :useComponent="QuestionnaireStartInfoDialog"
      @close="showStartInfo = false" />

    <Dialog
      :isShowing="showSaveInfo"
      :useComponent="QuestionnaireSaveInfoDialog"
      @close="showSaveInfo = false" />

    <BackToTop />
  </div>
</template>

<script>
import Header from '@/components/Questionnaire/Header.vue'
import Questions from '@/components/Questionnaire/Questions.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import BackToTop from '@/components/BackToTop.vue'
import QuestionnaireStartInfoDialog from '@/components/Questionnaire/QuestionnaireStartInfoDialog.vue'
import QuestionnaireSaveInfoDialog from '@/components/Questionnaire/QuestionnaireSaveInfoDialog.vue'
import { mapGetters } from 'vuex'
import { markRaw } from 'vue'

export default {
  name: 'Questionnaire',
  data() {
    return {
      QuestionnaireStartInfoDialog: markRaw(QuestionnaireStartInfoDialog),
      QuestionnaireSaveInfoDialog: markRaw(QuestionnaireSaveInfoDialog),
      showRecommendations: false,
      showStartInfo: false,
      showSaveInfo: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserAnswers',
      'currentQuestionSchema',
      'currentSchemaComments',
      'currentUserNextStep',
    ]),
  },
  methods: {
    onShowRecommendations() {
      this.showRecommendations = true
    },
    getComments() {
      this.$store.dispatch('getCurrentSchemaComments')
    },
    showStartInfoOnLoad() {
      if (this.showRecommendations) {
        return
      }

      const idArray = this.mixGetSessionStorageData({
        key: 'seenIntroTexts',
        defaultValue: [],
      })
      if (idArray.includes(this.currentQuestionSchema.id)) {
        return
      }

      this.showStartInfo = true
      this.mixSetSessionStorageData({
        key: 'seenIntroTexts',
        value: this.currentQuestionSchema.id,
        type: [],
      })
    },
  },
  components: {
    Header,
    Questions,
    BackToTop,
    Dialog,
  },
  created() {
    if (!this.currentQuestionSchema.id) {
      this.$router.push({ name: 'Start' })
      return
    }

    this.getComments()
  },
  mounted() {
    requestAnimationFrame(() => {
      this.showStartInfoOnLoad()
    })
  },
  unmounted() {
    this.$store.dispatch('resetCurrentSchemaComments')
  },
}
</script>

<style lang="stylus" scoped>
  .Questionnaire
    padding-bottom 100px

  .Intro
    width 700px
    margin 0 auto 20px
    p
      text-align center
    .Title
      text-align center
      font-size 1.5rem
      font-weight bold
      margin-bottom 20px
    .ShowRecommendations &
      margin-bottom 60px
</style>
