<template>
  <div
    v-if="item.groupTitle && item.showGroupTitleInList"
    class="GroupTitleWrap">
    <span
      class="GroupTitle"
      v-html="item.groupTitle"/>
  </div>
  <div
    :class="ItemClasses"
    class="QuestionItem">
    <span class="Title">{{ mixWB(item.wordbook) }}</span>
    <div class="OptionsWrap">
      <span
        :class="{ 'IsSelected': answerItem.answer === 1 }"
        @click="onAnswerClick(1)">{{ mixWB('YES_ALWAYS') }}</span>
      <span
        :class="{ 'IsSelected': answerItem.answer === 0 }"
        @click="onAnswerClick(0)">{{ mixWB('NO') }}</span>
      <span
        :class="{ 'IsSelected': answerItem.answer === 2 }"
        @click="onAnswerClick(2)">{{ mixWB('YES_AND_NO') }}</span>
    </div>
    <textarea
      :class="{ 'Expand': answerItem.comment.text }"
      :placeholder="mixWB('COMMENT')"
      :value="answerItem.comment.text"
      @input="onCommentUpdate" />

    <div
      v-if="showRecommendations"
      class="RecommendedWrap">
      <span
        class="Answer"
        v-if="item.recommended === 1">{{ mixWB('YES_ALWAYS') }}</span>
      <span
        class="Answer"
        v-if="item.recommended === 0">{{ mixWB('NO') }}</span>
      <span
        class="Answer"
        v-if="item.recommended === 2">{{ mixWB('YES_AND_NO') }}</span>

      <Button
        :text="item.id === selectedQuestionID ? mixWB('SELECTED') : mixWB('READ_MORE')"
        color="blue"
        size="small"
        :outlined="item.id !== selectedQuestionID"
        :fullWidth="false"
        @button-click="onInfoClick" />
    </div>
  </div>
</template>

<script>
import Button from '../Buttons/Button'

export default {
  name: 'QuestionItem',
  emits: [
    'answer-update',
    'comment-update',
    'info-click',
  ],
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    answerItem: {
      type: Object,
      required: true,
    },
    showRecommendations: {
      type: Boolean,
      required: true,
    },
    selectedQuestionID: {
      type: String,
      required: true,
    },
  },
  computed: {
    ItemClasses() {
      return {
        Selected: this.showRecommendations && this.item.id === this.selectedQuestionID,
        Even: this.index % 2 === 1,
        Odd: this.index % 2 === 0,
      }
    },
  },
  methods: {
    onAnswerClick(answer) {
      this.$emit('answer-update', { questionID: this.item.id, answer })
    },
    onCommentUpdate(e) {
      this.$emit('comment-update', { questionID: this.item.id, comment: e.target.value.trim() })
    },
    onInfoClick() {
      this.$emit('info-click', { questionID: this.item.id })
    },
  },
  components: { Button },
}
</script>

<style lang="stylus" scoped>
  .GroupTitleWrap
    padding-bottom 5px
    margin-bottom 10px
    border-bottom 1px solid $color_grey_dark
    margin-top 50px
    &:first-child
      margin-top 0px
    .GroupTitle
      display block
      font-weight bold

  .QuestionItem
    position relative
    display flex
    justify-content space-between
    align-items center
    flex-wrap wrap
    padding 15px
    &.Odd
      background-color $color_grey_lighter
      border 1px solid $color_grey_lighter
    &.Even
      background-color transparent
      border 1px solid $color_grey_lighter
    &.Selected
      background-color $color_blue_light_2
      border 1px solid $color_blue_light_2

  .Title
    width calc(100% - 250px)
    font-size 1.25rem

  .OptionsWrap
    display flex
    justify-content flex-end
    span
      padding 11px 10px 9px
      margin-left 10px
      min-width 60px
      background-color darken($color_grey_lighter, 10%)
      box-shadow 0 2px 3px rgba(0, 0, 0, 0.5)
      text-align center
      cursor pointer
      line-height 1
      font-size 0.875rem
      &:hover
        background-color darken($color_grey_lightest, 10%)
      &.IsSelected
        background-color $color_salmon
        color #fff
        &:hover
          background-color $color_salmon

  textarea
    width 100%
    min-width 100%
    max-width 100%
    height 44px
    min-height 44px
    max-height 44px
    margin-top 15px
    padding 10px 10px 8px
    border 1px solid $color_grey_lighter
    border-radius 0px
    background-color rgba(255, 255, 255, 0.75)
    &:focus,
    &.Expand
      height 120px
      min-height 120px
      max-height 120px

  .RecommendedWrap
    position absolute
    top -1px
    left calc(100% + 1px)
    z-index 2
    height calc(100% + 2px)
    flex-center-children-column()
    justify-content center
    padding 0 15px
    width 160px
    span
      display block
      padding 5px 0px
      text-align center
    .Answer
      font-weight bold
    >
      div
        width 100px
    .Odd &
      background-color $color_grey_lighter
      border-top 1px solid $color_grey_lighter
      border-bottom 1px solid $color_grey_lighter
    .Even &
      border-top 1px solid $color_grey_lighter
      border-bottom 1px solid $color_grey_lighter
    .Selected &
      background-color $color_blue_light_2
      border-top 1px solid $color_blue_light_2
      border-bottom 1px solid $color_blue_light_2
    +below($huge)
      width 140px

</style>
