<template>
  <div
    :class="rootClasses"
    class="RecommendationStatsColumn">
    <span class="Title">{{ title }}</span>
    <div class="PercentageWrap">
      <span class="Percentage">{{ percentage }} %</span>
      <span class="PercentageText">{{ percentageText }}</span>
    </div>
    <div class="CommentsWrap">
      <div
        v-if="isRecommended"
        class="Comment">
        <p><b>{{ mixWB('MILVA_AND_THE_EXPERTS_RECOMMENDATION') }}</b></p>
        <template v-if="selectedQuestion.explanationWordbook">
          <p class="ExplanationTitle"><b>{{ mixWB('EXPLANATION') }}</b></p>
          <p>{{ mixWB(selectedQuestion.explanationWordbook) }}</p>
        </template>
      </div>
      <div
        v-for="comment in answerComments"
        :key="comment.id"
        class="Comment">
        <p
          v-for="(line, index) in comment.text.split('\n')"
          :key="index">{{ line }}</p>
        <span class="GroupName">{{ getGroupName(comment) }}</span>
      </div>
    </div>
    <div
      v-if="!answerComments"
      class="CommentsLoading">
      <Circular />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Circular from '../Progress/Circular'

export default {
  name: 'RecommendationStatsColumn',
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    currentQuestionStats: {
      type: Object,
      required: true,
    },
    selectedQuestion: {
      type: Object,
      required: true,
    },
    currentQuestionComments: {
      type: [Array, Boolean],
      required: true,
    },
    segment: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'currentUserData',
      'currentGroupData',
      'allGroupsAsObject',
    ]),
    rootClasses() {
      return {
        Yes: this.type === 'yes',
        No: this.type === 'no',
        Both: this.type === 'both',
      }
    },
    typeAsNumber() {
      if (this.type === 'no') {
        return 0
      }
      if (this.type === 'yes') {
        return 1
      }
      return 2
    },
    percentageText() {
      let typeText
      if (this.type === 'yes') {
        typeText = this.mixWB('YES_ALWAYS').toLowerCase()
      }
      if (this.type === 'no') {
        typeText = this.mixWB('NO').toLowerCase()
      }
      if (this.type === 'both') {
        typeText = this.mixWB('YES_AND_NO').toLowerCase()
      }

      const usersToUse = this.segment === 'group' ? this.currentGroupData.name : this.mixWB('ALL_USERS').toLowerCase()

      return this.mixWB(
        'OF_X_HAS_ANSWERED_Y',
        [
          usersToUse,
          typeText,
        ],
      )
    },
    percentage() {
      const yes = this.currentQuestionStats['1'] || 0
      const no = this.currentQuestionStats['0'] || 0
      const both = this.currentQuestionStats['2'] || 0
      const total = yes + no + both

      if (this.type === 'yes') {
        return Math.round((yes / total) * 100)
      }
      if (this.type === 'no') {
        return Math.round((no / total) * 100)
      }

      return Math.round((both / total) * 100)
    },
    isRecommended() {
      return this.selectedQuestion.recommended === this.typeAsNumber
    },
    answerComments() {
      if (!this.currentQuestionComments) {
        return false
      }

      return this.currentQuestionComments.reduce((prev, comment) => {
        if (comment.answer !== this.typeAsNumber) {
          return prev
        }

        const group = this.allGroupsAsObject[comment.groupID]
        if (!group) {
          return prev
        }

        comment.groupName = group.name
        prev.push(comment)
        return prev
      }, [])
    },
  },
  methods: {
    getGroupName(comment) {
      if (this.segment === 'group') {
        return `${ comment.user.firstName } - ${ comment.groupName }`
      }

      return comment.groupName
    },
  },
  components: {
    Circular,
  },
}
</script>

<style lang="stylus" scoped>
  .RecommendationStatsColumn
    flex-basis 0
    flex-grow 2
    padding 10px 0px 0px
    margin-right 15px
    &:last-child
      margin-right 0
    &.Yes
      background-color $color_primary_lightest
    &.No
      background-color $color_salmon_lightest
    &.Both
      background-color $color_yellow_lightest
    +below($huge)
      margin-right 10px

  .Title
    display block
    text-align center
    padding-bottom 10px
    font-weight bold
    text-transform uppercase

  .PercentageWrap
    display block
    text-align center
    padding 10px
    .Percentage
      font-size 2rem
    .PercentageText
      font-size 0.75rem
    .Yes &
      border-top 1px solid $color_primary
      border-bottom 1px solid $color_primary
    .No &
      border-top 1px solid $color_salmon
      border-bottom 1px solid $color_salmon
    .Both &
      border-top 1px solid $color_yellow_dark
      border-bottom 1px solid $color_yellow_dark

  .Recommended
    display block
    text-align center
    font-size 0.875rem
    padding 10px
    font-weight bold
    .Yes &
      border-bottom 1px solid $color_primary
    .No &
      border-bottom 1px solid $color_salmon
    .Both &
      border-bottom 1px solid $color_yellow_dark

  .CommentsLoading
    position relative
    height 100px

  .ExplanationWrap,
  .CommentsWrap
    padding 10px 10px 0
    .SectionTitle
      display block
      text-align center
      text-transform uppercase
      margin-bottom 10px
    .Comment
      padding 10px 10px 0px
      font-size 0.875rem
      border-radius 3px
      margin-bottom 10px
      p
        font-size 0.75rem
      .Yes &
        background-color lighten($color_primary, 5%)
      .No &
        background-color $color_salmon
      .Both &
        background-color $color_yellow
      .ExplanationTitle
        padding-bottom 2.5px
      .GroupName
        display block
        font-size 0.665rem
        font-weight bold
        padding-bottom 10px
</style>
